import { registerApplication, start } from 'single-spa'
import React from 'react'
import ReactDOM from 'react-dom'
import LoadingApp from './loading-parcel'

window.addEventListener('single-spa:before-app-change', evt => {
  // hide error component
  const _errorElement = document.getElementById('error-app')
  _errorElement.style['display'] = 'none'

  // display loading component
  ReactDOM.render(<LoadingApp />, document.getElementById('loading-app'))
})

window.addEventListener('single-spa:app-change', (evt: any) => {
  // if some app breaks, it display error component
  if (evt.detail.appsByNewStatus.LOAD_ERROR?.length) {
    const _errorElement = document.getElementById('error-app')
    _errorElement.style['display'] = 'flex'
  }

  // hide loading component
  ReactDOM.unmountComponentAtNode(document.getElementById('loading-app'))
})

registerApplication({
  name: '@NKE/app-navbar',
  app: () => System.import('@NKE/app-navbar'),
  activeWhen: location =>
    !location.pathname.startsWith('/login') &&
    !location.pathname.startsWith('/order'),
})

// registerApplication({
//   name: '@NKE/app-home',
//   app: () => System.import('@NKE/app-home'),
//   activeWhen: location => location.pathname === '/',
// })

registerApplication({
  name: '@NKE/app-login',
  app: () => System.import('@NKE/app-login'),
  activeWhen: ['/login'],
})

// registerApplication({
//   name: "@NKE/app-platform",
//   app: () => System.import("@NKE/app-platform"),
//   activeWhen: ["/platform"],
//   customProps: {
//     userInfo: null,
//     LoadingComponet: ParcelLoading,
//   },
// });

registerApplication({
  name: '@NKE/app-new-platform',
  app: () => System.import('@NKE/app-new-platform'),
  activeWhen: ['/platform'],
})

// registerApplication({
//   name: "@NKE/app-models",
//   app: () => System.import("@NKE/app-models"),
//   activeWhen: ["/models"],
//   customProps: {
//     userInfo: null,
//     LoadingComponet: ParcelLoading,
//   },
// });

registerApplication({
  name: '@NKE/app-new-models',
  app: () => System.import('@NKE/app-new-models'),
  activeWhen: ['/models'],
})

registerApplication({
  name: '@NKE/app-spp',
  app: () => System.import('@NKE/app-spp'),
  activeWhen: ['/spp'],
})

registerApplication({
  name: '@NKE/app-settings',
  app: () => System.import('@NKE/app-settings'),
  activeWhen: ['/settings'],
})

registerApplication({
  name: '@NKE/app-order',
  app: () => System.import('@NKE/app-order'),
  activeWhen: ['/order'],
})

registerApplication({
  name: '@NKE/app-sidebar',
  app: () => System.import('@NKE/app-sidebar'),
  activeWhen: location =>
    !location.pathname.startsWith('/login') &&
    !location.pathname.startsWith('/order'),
})

start({
  urlRerouteOnly: true,
})
